"use client"
import { useState } from "react";
import { ContainerSection } from "./ContainerSection";
import Image from "next/image";
import { classNames } from "@/lib/utils";
// import { TextRevealCard, TextRevealCardDescription, TextRevealCardTitle } from "../presentational/tailwind/TextRevealCard";

export default function LandingVideo({ isReviewsPage }: { isReviewsPage?: boolean }) {
    const [iframeLoaded, setIframeLoaded] = useState<boolean>(false)

    const handleImageClick = () => {
        setIframeLoaded(true);
    }

    return (
        <ContainerSection id="demo" aria-label="Demo video of UpGrow's Instagram growth service" className={undefined}>
            <div className={classNames("px-3 section-gradient-2 lg:px-8",
                isReviewsPage ? 'pt-12 pb-12 sm:pt-20 sm:pb-12' : 'pt-14 sm:pt-24 sm:pb-5'
            )}>
                <div className="max-w-3xl mx-auto text-center">
                    {/* <TextRevealCard
                        className="mx-auto"
                        text="Say goodbye to bots & spam"
                        revealText="Say hello to safe, reliable growth"
                    /> */}
                    <span className="badge-gradient-2 mb-5 inline-flex rounded-full px-4 py-2 uppercase leading-6 tracking-[0.5px] text-indigo-600">
                        <span className="text-sm font-extrabold text-transparent text-gradient-2 bg-clip-text">
                            How does it work?
                        </span>
                    </span>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-7 sm:mb-10 md:text-5xl">
                        Your personal social media
                        <br className="hidden sm:block" />{" "}
                        team for{" "}
                        <br className="block sm:hidden" />{" "}
                        <span className="text-transparent text-gradient-2 bg-clip-text">...90% less*</span> 🤝
                    </h2>

                    <div className="w-full max-h-full overflow-hidden bg-black cursor-pointer rounded-3xl aspect-video" style={{ boxShadow: '0 16px 40px 0 rgba(0,0,0,.24)' }}>
                        <div className="relative section-gradient-2"
                            style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%' }}>
                            {iframeLoaded ? (
                                <iframe
                                    className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden rounded-2xl"
                                    width="100%"
                                    height="100%"
                                    src={`https://www.youtube.com/embed/drAk4w2BQAY?controls=1&rel=0&autoplay=1&playsinline=1&modestbranding&showinfo=0`}
                                    title="UpGrow Demo Video"
                                    allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            ) : (
                                <div className="relative flex justify-center cursor-pointer group">
                                    <Image
                                        className="cursor-pointer"
                                        width={768}
                                        height={432}
                                        src="/img/graphics/upgrow-instagram-preview.webp"
                                        alt="UpGrow Instagram Reviews"
                                        onClick={handleImageClick}
                                        style={{ cursor: 'pointer' }}
                                        loading="lazy"
                                        quality={60}
                                    />
                                    <div className="absolute inset-0 transition-opacity duration-200 bg-black opacity-30 group-hover:opacity-10"></div>
                                    {/* Play icon */}
                                    <svg className="absolute transition-all ease-in-out scale-100 group-hover:scale-90 opacity-90 hover:opacity-100" xmlns="http://www.w3.org/2000/svg" width="100" height="100" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                        onClick={handleImageClick}
                                    >
                                        <circle className="fill-white" cx="50" cy="50" r="40" />
                                        <path className="fill-purple-500 drop-shadow-2xl" d="M62 50l-20-14v28l20-14z" />
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ContainerSection>
    )
}