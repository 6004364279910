"use client"
import Image from 'next/image';
import { classNames } from '@/lib/utils';

const badges = [
  {
    title: 'G2 Best Relationship Winter 2021',
    img: '/img/awards/g2-high-performer-spring-2023.png',
  },
  {
    title: 'G2 Fastest Implementation Spring 2022',
    img: '/img/awards/g2-fastest-implementation-spring-2023.png',
  },
  {
    title: 'G2 High Performer Spring 2023',
    img: '/img/awards/sasworthy-fastest.webp',
  },
  {
    title: 'GetApp Leaders 2023',
    img: '/img/awards/saasworthy.webp',
  },
  {
    title: 'G2 Leader Spring 2023',
    img: '/img/awards/capterra-upgrow.svg',
  },
  {
    title: 'G2 Leader Winter 2023',
    img: '/img/awards/getapp-2023.svg',
  },
]

export default function LandingAwardBadges({ className }: { className?: string }) {
  return (
    <div className={classNames('relative mx-auto max-w-5xl', className)}>
      <div className="grid grid-cols-3 gap-4 md:col-span-3 md:grid-cols-6">
        {badges.map((badge, index) => (
          <div key={index} className="flex items-center justify-center">
            <div className="relative inline-block w-auto h-auto max-w-full p-0 m-0 overflow-hidden border-0 opacity-100 bg-none">
              <Image alt={`UpGrow has been nominated for ${badge.title}`} src={badge.img} className="h-[7.5rem] w-auto max-w-[105px] object-contain" loading='lazy' width={95} height={120} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
